<template>
  <div class="">
    <div class="" v-if="!showEmailSubmitted">
      <SupportHeading
        heading="Call us"
        subHeading="Available Monday - Friday, from 9:00-17:00"
      ></SupportHeading>
      <div class="d-flex flex-column" style="gap: 24px">
        <!-- <div class="" data-aos="fade-right" data-aos-delay="300">
          <label for="email" class="support-label"> Global </label>
          <div class="support-input disabled-input">
            <div class="d-flex" style="gap: 12px">
              <div class="">🌍</div>
              <a href="tel:+442079460958">+44 20 7946 0958</a>
            </div>

            <div class="" style="cursor: pointer">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 14H19C20.1046 14 21 13.1046 21 12V5C21 3.89543 20.1046 3 19 3H12C10.8954 3 10 3.89543 10 5V6.5M5 10H12C13.1046 10 14 10.8954 14 12V19C14 20.1046 13.1046 21 12 21H5C3.89543 21 3 20.1046 3 19V12C3 10.8954 3.89543 10 5 10Z"
                  stroke="#292D35"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div> -->
        <div class="" data-aos="fade-left" data-aos-delay="300">
          <label for="email" class="support-label"> United States </label>
          <div class="support-input disabled-input">
            <div class="d-flex" style="gap: 12px">
              <div class="">&#127482;&#127480;</div>
              <a href="tel:+16463741287">(646) 374-1287</a>
            </div>

            <div class="" style="cursor: pointer" @click="copyContactNumber('+16463741287')">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 14H19C20.1046 14 21 13.1046 21 12V5C21 3.89543 20.1046 3 19 3H12C10.8954 3 10 3.89543 10 5V6.5M5 10H12C13.1046 10 14 10.8954 14 12V19C14 20.1046 13.1046 21 12 21H5C3.89543 21 3 20.1046 3 19V12C3 10.8954 3.89543 10 5 10Z"
                  stroke="#292D35"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-right"
          data-aos-delay="300"
          class="or d-flex align-items-center"
        >
          <span class="mx-2">OR</span>
        </div>
        <div
          class="support-call-card"
          data-aos="fade-right"
          data-aos-delay="300"
        >
          <div class="d-flex justify-content-center w-100 mb-3">
            <div class="call-heading">Let us know your contact number</div>
          </div>
          <div class="call-sub-heading mb-3">
            We'll get back to you during our working hours.
            
          </div>
          <div class="">
            <div
              class="support-input mb-3"
              :class="
                !phoneValid && !contactNumber && isSubmit ? 'invalidField' : ''
              "
              style="background-color: white"
            >
              <VueTelInput
                class="tel-number"
                :inputOptions="phoneInputOption"
                :dropdownOptions="dropdown"
                v-model="contactNumber"
                @validate="onValidation"
                :validCharactersOnly="true"
              />
            </div>
            <i
              class="text-danger"
              v-show="
                !phoneValid && !contactNumber && isSubmit ? 'invalidField' : ''
              "
              >Please enter valid contact number.</i
            >
            <button
              :disabled="isLoading"
              class="support-button w-100"
              @click="saveLead"
            >
              <span v-show="isLoading">
                <b-spinner small class="mr-2"></b-spinner> Adding in contact
                diary...
              </span>
              <span v-show="!isLoading">Call Me</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div data-aos="fade-up" class="" v-else>
      <SupportHeading
        :isLine="false"
        heading="Your call is all set!"
        subHeading="We'll get in touch with you within 15 minutes"
      ></SupportHeading>
      <button
        href=""
        class="support-button d-flex justify-content-center w-100"
        @click="reloadToMainSite"
      >
        <div class="mr-2">&lt;</div>
        Back to homepage
      </button>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import SupportHeading from "./SupportHeading.vue";
import axios from "axios";
import { mapGetters } from "vuex/dist/vuex.common.js";
export default {
  components: {
    SupportHeading,
    VueTelInput,
  },
  data() {
    return {
      isSubmit: false,
      dropdown: {
        showSearchBox: true,
        showFlags: true,
        width: "390px",
      },
      contactNumber: "",
      phoneInputOption: {
        placeholder: "Enter Phone Number",
        maxlength: 15,
      },
      query: {
        api_token: this.$route.query.api_token,
        quizId: this.$route.query.quiz_id,
      },
      showEmailSubmitted: false,
      isLoading: false,
      phoneValid: false,
    };
  },
  computed: {
    ...mapGetters(["getSavedLeadID"]),
  },
  async mounted() {
    // console.log(query,)
    await this.$store.dispatch(`updateQuizAnalysesAction`, this.query);
  },
  methods: {
    copyContactNumber(number){
        const th = this
        navigator.clipboard.writeText(number).then(()=> {
            th.$toasted.show("Contact number has been copied", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
    }).catch(function(e) {
        console.log(e)
        th.$toasted.show("Field to Copy", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
    });
    },
    onValidation(e) {
      if (e.valid !== undefined) {
        this.phoneValid = !e.valid;
      } else {
        this.phoneValid = false;
      }
    },
    reloadToMainSite() {
      window.parent.location.href = process.env.VUE_APP_MAIN_WEBSITE;
    },
    async saveLead() {
      console.log(this.phoneValid || !this.contactNumber);
      //    return
      // eslint-disable-next-line no-unreachable
      this.isSubmit = true;
      if (this.phoneValid || !this.contactNumber) return;
      this.isLoading = true;
      try {
        const leadData = { ...this.getSavedLeadID };
        leadData.phone_number = this.contactNumber;
        let data = {
          ...this.query,
          quiz_analytic_id: this.getSavedLeadID.quiz_analytic_id,
          leadData: JSON.stringify(leadData),
        };
        const response = await axios.post(`/api/saveFinalLead`, data);
        if (response.status == 200) {
          this.showEmailSubmitted = true;
        }
      } catch (e) {
        console.log(e);
        this.$toasted.show("Server Error", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
        throw e;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
.invalidField {
  border: 1px solid #ea0101 !important;
  box-shadow: 0px 1px 2px 0px #ff0b0bd6 !important;
  animation: shake 0.5s ease;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
.disabled-input {
  background: #f9f8f9;
}
.support-input div a {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.04em;
  color: #4d1b7e;
  text-decoration: none;
}
.or::after,
.or::before {
  content: "";
  height: 1px;
  width: 100%;
  background: #ededed;
  display: block;
}
.or {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #667085;
}
.support-call-card {
  padding: 28px 24px 24px 24px;
  gap: 28px;
  border-radius: 8px;
  background: #f9f8f9;
}
.call-heading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  text-align: justify;
  width: 200px;
  color: #292d35;
}
.call-sub-heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #667085;
}
.tel-number {
  flex: 1;
  border: none;
}
.tel-number .vti__dropdown {
  padding: 0px;
}
.tel-number:focus-within {
  outline: none;
  border: none;
  box-shadow: none;
}
</style>
