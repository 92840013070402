<template>
    <div class="">
        <div class="" v-if="!showEmailSubmitted">
    <SupportHeading :heading="'Email us'" :subHeading="'We’ll aim to respond within 1 business day'"></SupportHeading>
    <div class="d-flex flex-column" style="gap:24px">
        <div data-aos="fade-left" data-aos-delay="300" class="">
            <label for="email" class="support-label">
               Your Email
            </label>
            <div class="support-input " :class="(!isValidEmail(emailDetail.email))&&isSubmit?'invalidField':''">
                <input id="email" v-model.trim="emailDetail.email" type="email" required style="flex-grow: 1;" placeholder="Email">
            </div>
            <i class="text-danger" v-show="(!isValidEmail(emailDetail.email))&&isSubmit?'invalidField':''">Please enter a valid email</i>
        </div>
        <div class=""  data-aos="fade-right" data-aos-delay="300">
            <label for="description" class="support-label">
                Question
            </label>
            <div class="support-input" :class="(!emailDetail.detail)&&isSubmit?'invalidField':''" >
                <textarea id="description" v-model.trim=emailDetail.detail required style="flex-grow: 1; height: 202px; resize: none;" placeholder="Ask a question">
                    </textarea>
            </div>
            <i class="text-danger" v-show="(!emailDetail.detail)&&isSubmit?'invalidField':''">Please enter your question detail</i>
        </div>
        <button  :disabled="isLoading" class="support-button w-100" @click="saveLead">
            <span v-show="isLoading"> <b-spinner small class="mr-2"></b-spinner> Sending... </span>
            <span  v-show="!isLoading">Send</span>
        </button>
    </div>
  </div>
  <div data-aos="fade-up" class="" v-else>
                <SupportHeading :isLine="false" heading="Thank you!" subHeading="Your email and details have been successfully sent. Our team will review your message and get back to you within 1 business day"></SupportHeading>
                <button href="" class="support-button d-flex justify-content-center w-100" @click="reloadToMainSite"><div class="mr-2">&lt;</div>Back to homepage</button>
            </div>
    </div>
 
</template>

<script>
import SupportHeading from './SupportHeading.vue';
import { mapGetters } from 'vuex/dist/vuex.common.js';
import axios from 'axios';
export default {
components:{
    SupportHeading
},
computed:{

    ...mapGetters([
      "getSavedLeadID"
    ]),
   
    ValidationCheck(){
    return (this.isValidEmail(this.emailDetail.email)&&this.emailDetail.detail)
}
},
data(){
return{
    emailDetail:{
        email:'',
        detail:''
    },
    showEmailSubmitted:false,
    isLoading:false,
    isSubmit:false,
    query:{
        api_token:this.$route.query.api_token,
        quizId:this.$route.query.quiz_id,
    }
}
},

methods:{
    reloadToMainSite()
    {
        window.parent.location.href=process.env.VUE_APP_MAIN_WEBSITE;
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
async saveLead(){
   
    this.isSubmit=true;
    if(!this.ValidationCheck)
    return
    this.isLoading=true;
    try{
        const leadData = {...this.getSavedLeadID}
    leadData.email = this.emailDetail.email;
    leadData.custom_inputs.detail =  this.emailDetail.detail;
        let data = {
        ...this.query,
        quiz_analytic_id: this.getSavedLeadID.quiz_analytic_id,
        leadData:JSON.stringify(leadData)
    }
    const response = await axios.post(`/api/saveFinalLead`, data);
    console.log(response.status)
    if(response.status==200)
    {
        this.showEmailSubmitted=true;
    }
    }
    catch(e){
        this.$toasted.show("Server Error", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        throw e
    }
    finally{
        this.isLoading=false;
    }
    
  
}
},
async mounted(){
    // console.log(query,)
await  this.$store.dispatch(`updateQuizAnalysesAction`,this.query)
}
}
</script>

<style>
.invalidField{
    border: 1px solid #ea0101!important;
    box-shadow: 0px 1px 2px 0px #ff0b0bd6!important;
    animation: shake 0.5s ease;
}
@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
    100% { transform: translateX(0); }
}

</style>